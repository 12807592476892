<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
              >
                <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card>
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="退货单编号:"
                        label-for="returnbound_no"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="returnboundNo"
                          class="d-inline-block mr-1"
                          size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售单编号:"
                        label-for="order_no"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="orderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="渠道:"
                        label-for="channel_Name"
                        label-size="sm"
                >
                  <b-form-input
                          id="channel_Name"
                          size="sm"
                          v-model="channelName"
                          @click="showSingleModal('渠道')"
                          readonly
                          placeholder="点击搜索渠道"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售主体:"
                        label-for="company_name"
                        label-size="sm"
                >
                  <b-form-input
                          id="company_name"
                          size="sm"
                          v-model="companyName"
                          @click="showSingleModal('销售主体')"
                          readonly
                          placeholder="点击搜索销售主体"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="入库仓库:"
                        label-for="warehouse_name"
                        label-size="sm"
                >
                  <b-form-input
                          id="warehouse_name"
                          size="sm"
                          v-model="warehouseName"
                          @click="showSingleModal('入库仓库')"
                          readonly
                          placeholder="点击搜索入库仓库"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="状态:"
                        label-for="status_id"
                        label-size="sm"
                >
                  <v-select
                          id="status_id"
                          :options="getCodeOptions('sales_returnbound_status')"
                          :clearable="true"
                          v-model="statusId"
                          class="select-size-sm"
                          placeholder="选择状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="退货原因:"
                        label-for="reason"
                        label-size="sm"
                >
                  <v-select
                          id="reason"
                          :options="getCodeOptions('sales_returnbound_reasontype')"
                          :clearable="true"
                          v-model="reason"
                          class="select-size-sm"
                          placeholder="选择原因"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="创建人:"
                        label-for="creator"
                        label-size="sm"
                >
                  <b-form-input
                          id="creator"
                          size="sm"
                          v-model="creator"
                          @click="showSingleModal('创建人')"
                          readonly
                          placeholder="点击搜索创建人"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="创建时间:"
                        label-for="add_time"
                        label-size="sm"
                >
                  <flat-pickr
                          :config="{ mode: 'range'}"
                          class="form-control"
                          v-model="addTime"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="商品名称:"
                        label-for="product"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="product"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="支持输入空格后多级搜索"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>




      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns 显示隐藏详细信息 -->
        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                </template>

                <!-- Columns -->
                <template #cell(inventory_type)="data">
                  {{ getCodeLabel('inbound_inventory_type', data.item.inventory_type) }}
                </template>

                <template #cell(product_unit)="data">
                  {{ getCodeLabel('product_unit', data.item.ext.product_unit) }}
                </template>

                <template #cell(item_cost)="data">
                  <div v-if="isNumber(data.item.is_return)>0">
                    <div v-if="isNumber(data.item.service_id)>0&&(isNumber(data.item.salesreturnqty_good)+isNumber(data.item.salesreturnqty_bad))==0">{{isNumber(data.item.product_cost_tax)}}</div>
                    <div v-else>{{ (isNumber(data.item.salesreturnqty_good)+isNumber(data.item.salesreturnqty_bad))*data.item.product_cost_tax }}</div>
                  </div>
                  <div v-else>
                    {{ data.item.salesreturnqty*data.item.product_cost_tax }}
                  </div>
                </template>
              </b-table>
            </div>
          </b-card>
        </template>


        <template #cell(no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [{{ data.item.returnbound_id }}] {{ data.item.returnbound_no }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`no-row-${data.item.id}`"
          />
          <b-tooltip
                  :target="`no-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              退货单编号：{{ data.item.returnbound_no || "" }}
            </p>
            <p class="mb-0">
              销售出库单编号：{{ data.item.outbound_no || "" }}
            </p>
            <p class="mb-0">
              销售单编号：{{ data.item.order_no || "" }}
            </p>

          </b-tooltip>
        </template>

        <template #cell(returnInfo)="data">
          {{ getCodeLabel('sales_returnbound_reasontype', data.item.reason) }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`carrier-row-${data.item.id}`"
                  v-show="data.item.carrier"
          />

          <b-tooltip
                  :target="`carrier-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              退货原因：{{ getCodeLabel('sales_returnbound_reasontype', data.item.reason) }}
            </p>
            <p class="mb-0">
              售后类型：{{ getCodeLabel('sales_returnbound_servicetype', data.item.service) }}
            </p>
            <p class="mb-0">
              费用承担方：{{ getCodeLabel('sales_returnbound_responsibility', data.item.responsibility) }}
            </p>
            <p class="mb-0">
              物流方式：{{ getCodeLabel('delivery_method_return', data.item.delivery_type) }}
            </p>
            <p class="mb-0">
              承运商：{{ data.item.carrier || "" }}
            </p>
            <p class="mb-0">
              快递单号：{{ data.item.express_no || "" }}
            </p>
          </b-tooltip>
        </template>


        <template #cell(company_name)="data">
          {{ data.item.company_name }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`company-row-${data.item.id}`"
          />
          <b-tooltip
                  :target="`company-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              销售主体：{{ data.item.company_name || "" }}
            </p>
            <p class="mb-0">
              销售员：{{ data.item.sales_name || "" }}
            </p>
            <p class="mb-0">
              联系电话：{{ data.item.sales_mobile || "" }}
            </p>

          </b-tooltip>
        </template>

        <template #cell(our_contact)="data">
          {{ data.item.our_contact }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`our_contact-row-${data.item.id}`"
          />
          <b-tooltip
                  :target="`our_contact-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              联系电话：{{ data.item.our_mobile || "" }}
            </p>

          </b-tooltip>
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon v-if="data.value" icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
                  :target="`remark-row-${data.item.id}`"
                  placement="left"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(other_contact)="data">
          {{ data.item.other_contact }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`other_contact-row-${data.item.id}`"
          />
          <b-tooltip
                  :target="`other_contact-row-${data.item.id}`"
                  placement="top"
          >
            <p class="mb-0">
              联系电话：{{ data.item.other_mobile || "" }}
            </p>

          </b-tooltip>
        </template>

        <template #cell(delivery_time)="data">
          {{ toTime(data.item.delivery_time) }}
        </template>

        <template #cell(arrival_time)="data">
          {{ toTime(data.item.arrival_time) }}
        </template>

        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>
        <!-- Column: type_id -->
        <template #cell(type_id)="data">
          {{ getCodeLabel('business_type', data.item.type_id) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <!-- 附加小费 -->
        <template #cell(extra_cost_subtotal)="data">
          <div :id="`extra_cost_subtotal-row-${data.item.id}`">
            {{ data.value }}
            <span v-if="data.value > 1000000"> ...</span>
            <feather-icon icon="EditIcon" @click="showFee(data.item)"/>
          </div>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('sales_returnbound_status',data.value)}`"

          >
            {{ getCodeLabel('sales_returnbound_status', data.value) }}
          </b-badge>


        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
                  variant="link"
                  no-caret
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status !== 0 "
                             :to="{ name: 'apps-salesreturnbound-view', query: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看退货单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0" @click="commit(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交仓库</span>

            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0" @click="deleteModal(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>

            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 3" @click="showModal(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">入库明细</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 3" @click="createAllOutBound(data.item.id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">生成调拨申请单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 0"
                             :to="{ name: 'apps-salesreturnbound-edit', query: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑退货单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
              id="modal-inbound-item"
              ok-only
              ok-title="确认"
              cancel-title="取消"
              centered
              size="xl"
              title="查看入库明细"
              ref="modal"
      >
        <stock-returnbound-item-list ref="returnboundItem" :returnbound_id="returnbound_id">

        </stock-returnbound-item-list>
      </b-modal>
    </b-card>
    <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="修改备注"
            ref="remarkModal"
    >
      <b-card :header="`编号：${selectedItem.returnbound_no}`">
        <b-form-textarea
                id="textarea"
                v-model="selectedItem.remark"
                rows="3"
                max-rows="6"
        />
      </b-card>
    </b-modal>

    <!-- 附加费弹出窗口 -->
    <b-modal
            id="feeModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="xl"
            title="附加费列表"
            ref="feeModal"
            @hidden="resetTable"
    >
      <fee-list
              ref="FeeList" :item="item">
      </fee-list>
    </b-modal>

    <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
    >

      <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '销售主体' || modalName === '渠道' "
      >
      </company-select>

      <user-select
              ref="userSelect" v-if="modalName ==='创建人'"
      >
      </user-select>

      <WareHouse-Select
              ref="whSelect" v-if="modalName === '调出仓库' || modalName === '入库仓库' "
      >
      </WareHouse-Select>


    </b-modal>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted} from '@vue/composition-api'
  import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber} from '@core/utils/filter'
  import salesreturnboundUseList from './salesreturnboundUseList'
  import salesreturnboundStore from './salesreturnboundStore'
  import {useToast} from 'vue-toastification/composition'
  import StockReturnboundItemList from '@/views/apps/stockreturnbounditem/returnboundItem-modal/StockReturnboundItemList'
  import FeeList from "@/views/apps/fee/FeeList"
  import CompanySelect from '@/views/apps/company/CompanySelect'
  import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
  import Ripple from "vue-ripple-directive"
  import UserSelect from '@/views/apps/user/user-modal/UserSelect'

  export default {
    components: {
      UserSelect,
      StockReturnboundItemList,
      WareHouseSelect,
      CompanySelect,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      FeeList,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        returnbound_id: 0,
        selectedItem: {},
        // 选中行的数据
        item: {},
        fields: [
          {key: 'ext', label: '商品69码'},
          {key: 'name', label: '名称'},
          {key: 'product_unit', label: '单位'},
          {key: 'order_no', label: '销售单编号'},
          // {key: 'qty', label: '计划出库数量'},
          //{key: 'trueqty', label: '实际出库数量'},
          // {key: 'receiveqty', label: '对方实收数量'},
          {key: 'salesreturnqty', label: '预计退货数量'},
          {key: 'salesreturnqty_good', label: '实际退货数量(良品)  '},
          {key: 'salesreturnqty_bad', label: '实际退货数量(不良品)  '},
          {key: 'product_cost_tax', label: '销售价格  '},
          {key: 'cost_price', label: '销售成本  '},
          {key: 'item_cost', label: '小计  '},
        ],
        companyType: '',
        modalName: '',
        channelName: '',
        companyName: '',
        warehouseName: '',
        creator:'',
      }
    },
    methods: {
      showModal(id) {
        this.returnbound_id = id
        this.$refs['modal'].show()
      },
      showRemark(item) {
        this.selectedItem = item
        this.$refs['remarkModal'].show()
      },
      showFee(item) {
        // 附加费窗口处理方法
        this.item = item
        this.item.fee_type_id = 3
        this.item.relational_id = item.returnbound_id
        this.item.relational_no = item.returnbound_no
        this.$refs['feeModal'].show()
      },
      resetTable() {
        this.$refs.refListTable.refresh()
      },

      showSingleModal(modalName) {
        if (modalName === '销售主体') {
          this.companyType = 'Group'
        } else if (modalName === '渠道') {
          this.companyType = 'OffChannelAndChannel'
        }
        this.modalName = modalName
        this.$refs['singleModal'].show()
      },

      onSelectSingle() {
        let data = {}
        // this.$refs.itemList.refetchData();
        switch (this.modalName) {
          case '渠道': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              this.channelName = data[0].company_name;
              this.channelId = data[0].company_id;
            }
            break
          }
          case '销售主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              this.companyName = data[0].company_name;
              this.companyId = data[0].company_id;
            }
            break;
          }
          case '入库仓库': {
            data = this.$refs.whSelect.getSelected()
            if (data.length > 0) {
              this.warehouseName = data[0].warehouse_name;
              this.warehouseId = data[0].warehouse_id;
            }
            break;
          }
          case '创建人': {
            data = this.$refs.userSelect.getSelected()
            if (data.length > 0) {
              this.creator = data[0].full_name;
              this.creatorId = data[0].user_id;
            }
            break;
          }
          default: {
            //statements;
            break
          }
        }
      },
      searchByCondition() {
        this.refetchData()
      },
      resetCondition() {
        this.returnboundNo = '';
        this.orderNo = '' ;
        this.channelId = '';
        this.channelName = ''
        this.companyId = '';
        this.companyName = ''
        this.warehouseId = '';
        this.warehouseName = ''
        this.creator = '';
        this.creatorId = ''
        this.statusId = '';
        this.reason = '';
        this.addTime = '';
        this.product = '';
        this.refetchData()
      },

    },
    setup() {
      const toast = useToast();
      // Register module
      if (!store.hasModule('salesreturnbound')) store.registerModule('salesreturnbound', salesreturnboundStore)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('salesreturnbound')) store.unregisterModule('salesreturnbound')
      })

      const commit = (id) => {
        if (confirm("是否提交")){
          store.dispatch('salesreturnbound/commit', {returnboundId: id})
                  .then((res) => {
                    if (res.data.code === 0) {
                      toast.success('提交成功')
                      refetchData()
                    }else{
                      toast.error(res.data.data)
                      refetchData()
                    }
                  })
        }
      }


      const deleteModal = (id) => {
        if (confirm("是否删除")){
          store.dispatch('salesreturnbound/delete', {id: id})
                  .then((res) => {
                    if (res.data.code === 0) {
                      toast.success('删除成功')
                      refetchData()
                    }else{
                      toast.error(res.data.data)
                      refetchData()
                    }
                  })
        }
      }


      const saveRemark = function () {
        store.dispatch('salesreturnbound/save', this.selectedItem).then(res => {
          if (res.data.code === 0) {
            toast.success("修改成功")
          } else {
            toast.error("修改失败")
          }
        })
      }

      const createAllOutBound = function (data) {
        store.dispatch('salesreturnbound/createAllOutBound', {salesReturnBoundId: data}).then(res => {
          if (res.data.code === 0) {
            this.$router.push({name: 'apps-allotoutbound-edit', query: {id: res.data.data}, params: {readonly: 0}})
          } else {
            toast.error("创建失败")
          }
        })


      }


      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        returnboundNo,
        orderNo,
        channelId,
        companyId,
        warehouseId,
        creatorId,
        statusId,
        addTime,
        product,
        reason,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // UI
      } = salesreturnboundUseList()

      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        returnboundNo,
        orderNo,
        channelId,
        companyId,
        warehouseId,
        creatorId,
        addTime,
        product,
        statusId,
        reason,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        saveRemark,


        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        commit,
        deleteModal,
        toTime,
        createAllOutBound,
        isNumber,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
