import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function salesreturnboundUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'no', label: '编号'},
    // { key: 'returnbound_no', label: '退货单编号', sortable: true },
    // { key: 'outbound_id', label: '销售出库单ID', sortable: true },
    // { key: 'outbound_no', label: '销售出库单编号', sortable: true },
    // { key: 'order_id', label: '销售单ID', sortable: true },
    //  { key: 'order_no', label: '销售单编号', sortable: true },
    // { key: 'type_id', label: '业务类型', sortable: true },
    // { key: 'channel_id', label: '渠道ID', sortable: true },
    { key: 'channel_name', label: '渠道', sortable: true },
    // { key: 'company_id', label: '销售主体ID', sortable: true },
    { key: 'company_name', label: '销售主体', sortable: true },
    // { key: 'sales_id', label: '销售员ID', sortable: true },
    // { key: 'sales_name', label: '销售员名称', sortable: true },
    // { key: 'sales_mobile', label: '销售员联系电话', sortable: true },
    // { key: 'warehouse_id', label: '入库仓仓库ID', sortable: true },
    { key: 'warehouse_name', label: '入库仓仓库', sortable: true },
    { key: 'returnInfo', label: '退货信息' },
   /* { key: 'reason', label: '退货原因', sortable: true },
    { key: 'service', label: '售后类型', sortable: true },
    { key: 'responsibility', label: '费用承担方', sortable: true },
    { key: 'delivery_type', label: '物流方式', sortable: true },
    { key: 'carrier', label: '承运商', sortable: true },*/
    // { key: 'express_no', label: '快递单号', sortable: true },
    // { key: 'our_contact', label: '收货方', sortable: true },
    // { key: 'our_mobile', label: '收货方联系电话', sortable: true },
    // { key: 'other_contact', label: '退货方', sortable: true },
    // { key: 'other_mobile', label: '退货方联系电话', sortable: true },
    // { key: 'extra_cost_subtotal', label: '附加费小计', sortable: true},
    // { key: 'delivery_time', label: '发货时间', sortable: true },
    // { key: 'arrival_time', label: '预计到货时间', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('status,add_time desc')
  const isSortDirDesc = ref(true)
  const returnboundNo = ref('')
  const orderNo = ref('')
  const channelId = ref('')
  const companyId = ref('')
  const warehouseId = ref('')
  const statusId = ref('')
  const reason = ref('')
  const creatorId = ref('')
  const addTime = ref('')
  const product = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesreturnbound/search', {
        search_key: searchKey.value,
        returnboundNo: returnboundNo.value==''?null:returnboundNo.value,
        orderNos:orderNo.value==''?null:orderNo.value,
        channelId : channelId.value,
        companyId: companyId.value,
        warehouseId: warehouseId.value,
        creator: creatorId.value,
        addTime: addTime.value,
        product: product.value,
        statusId:  statusId != null ? statusId.value.value : "",
        reason:  reason != null ? reason.value.value : "",
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? '':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '销售退货单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    returnboundNo,
    orderNo,
    channelId,
    companyId,
    warehouseId,
    statusId,
    reason,
    orderBy,
    isSortDirDesc,
    refListTable,
    creatorId,
    addTime,
    product,
    refetchData,

    // Extra Filters
  }
}
